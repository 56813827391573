form {
  padding-top: 12px;
  padding-bottom: 12px;
}

form > div {
  min-width: 120px !important;
}

div.select {
  font-size: 14px;
  font-weight: 500;
}

li.select-item {
  font-size: 14px;
}

p.marketplace-item-big-title {
  font-weight: 500;
  padding-top: 8px;
}

div.marketplace-item-big-container {
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}

div.marketplace-item-big-box-left {
  max-width: 45%;
  padding-right: 4px;
}

div.marketplace-item-big-box-right {
  max-width: 55%;
  padding-left: 4px;
}

div.marketplace-item-big-box-right > div {
  justify-content: flex-end;
}

div.marketplace-item-big-comment {
  display: flex;
  padding-top: 8px;
}

span.marketplace-item-big-comment-text {
  font-style: italic;
}
