div.budget-item-small {
  padding-top: 8px;
  padding-bottom: 8px !important;
  padding-right: 0px;
}

div.budget-item-small-header {
  display: flex;
  align-items: center;
}

p.budget-item-small-amount {
  width: 23%;
  padding-right: 4px;
  text-align: right;
}

p.budget-item-small-type {
  width: 21%;
  padding-right: 4px;
  text-align: right;
}

p.budget-item-small-date {
  width: 37%;
  padding-right: 4px;
  text-align: right;
}

div.budget-item-small-button {
  width: 19%;
  min-height: 32px;
  display: flex;
  justify-content: center;
}

div.budget-item-small-details {
  margin-top: 4px;
  margin-bottom: 8px;
  margin-right: 16px;
}

div.budget-item-big-header {
  display: flex;
  justify-content: space-between;
}

div.budget-item-big-header > p {
  font-weight: 500;
  padding-top: 8px;
}

p.budget-item-big-amount {
  width: 20%;
  text-align: left;
}

p.budget-item-big-type {
  width: 20%;
  text-align: center;
}

p.budget-item-big-date {
  width: 20%;
  text-align: right;
}

p.budget-item-big-title {
  font-weight: 500;
  font-style: italic;
  padding-top: 16px;
}

div.budget-item-big-container {
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}

div.budget-item-big-box-left {
  max-width: 45%;
  padding-right: 4px;
}

div.budget-item-big-box-right {
  max-width: 55%;
  padding-left: 4px;
}

div.budget-item-big-box-right > div {
  justify-content: flex-end;
}
