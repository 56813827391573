@media (max-device-width: 599px) {
  header {
    margin: 8px 8px 0px 8px;
  }
}

@media (min-device-width: 600px) {
  header {
    margin: 16px 16px 0px 16px;
  }
}

.right-box {
  display: inline-block;
}

.right-box-content {
  float: right;
  clear: both;
}

.right-margin {
  margin-right: 16px !important;
}

h3 + span {
  margin-top: -6px !important;
}

h5 + span {
  margin-top: -6px !important;
}
