p.hw-list-item-big-title {
  font-weight: 500;
  padding-top: 8px;
}

div.hw-list-item-big-container {
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}

div.hw-list-item-big-box-left {
  max-width: 45%;
  padding-right: 4px;
}

div.hw-list-item-big-box-right {
  max-width: 55%;
  padding-left: 4px;
}

div.hw-list-item-big-box-right > div {
  justify-content: flex-end;
}

div.hw-list-item-big-comment {
  display: flex;
  padding-top: 8px;
}

span.hw-list-item-big-comment-text {
  font-style: italic;
}
