body {
  overflow: hidden;
}

div#root {
  height: 100vh;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

article {
  overflow-y: auto;
}

@media (min-width: 600px) {
  .before-article, article {
    margin: 8px 16px 0px 16px;
  }
}

@media (max-width: 599px) {
  .before-article, article {
    margin: 8px 8px 0px 8px;
  }
}

article > div {
  margin-top: 8px;
}

article > div:first-child {
  margin-top: 2px;
}

article > div:last-child {
  margin-bottom: 4px;
}

div.card-inactive {
  background-color: #cfd8dc;
}

div.card-marketplace {
  background-color: #c5cAe9;
}

div.card-zero {
  background-color: #cfd8dc;
}

div.card-plus {
  background-color: #c8e6c9;
}

div.card-minus {
  background-color: #ffcdd2;
}

div.small-card-actions {
  padding-top: 0px;
  margin-top: -8px;
  float: right;
}
